import React, { useEffect, useRef, useContext } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { TimelineLite } from "gsap/TweenMax";
import GraphiteElectrode from "../svg/electrodes.svg";
import RightChevron from "../svg/chevron-right-solid.svg";
import Vivus from "vivus";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const Section1 = styled.section`
  min-height: calc(100vh - 75px);
  position: relative;
  padding-top: 75px;
  width: 100%;
  background-color: #363636;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeroHeader = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 70rem;
  color: #eee;
  opacity: 0;
  margin: 0 0 15px 0;
  position: relative;
  border-left: 2rem solid #eee;
  padding-left: 25px;
  line-height: 1.2;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 64rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 54rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 50rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroSubheader = styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 26rem;
  color: #eee;
  opacity: 0;
  margin: 0;
  position: relative;
  grid-column: 1;
  
  @media screen and (max-width: 1440px) {
    font-size: 24rem;
  }
  
  @media screen and (max-width: 1220px) {
    font-size: 22rem;
  }
  
  @media screen and (max-width: 1020px) {
    font-size: 20rem;
  }
  
  @media screen and (max-width: 900px) {
   grid-column: 1 / 3;
  }
`;

const HeroBody = styled.p`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  padding-bottom: 0;
  opacity: 0;
  position: relative;
  grid-column: 1;
  
  & + & {
    margin-top: 10px;
  }
  
  @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
  
  @media screen and (max-width: 900px) {
    grid-column: 1 / 3;
  }
  
`;

const HeroWrapper = styled.div`
  display: grid;
  position: relative;
  width: 95%;
  margin: auto;
  flex: 0;
  justify-content: center;
  align-items: center;
  grid-template-columns: 700px auto;
  grid-template-rows: auto auto auto auto auto auto;
  row-gap: 5px;
  
  @media screen and (max-width: 1220px) {
    grid-template-columns: 600px auto;
  }
  
  @media screen and (max-width: 1020px) {
    grid-template-columns: 550px auto;
  }
  
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media screen and (max-width: 500px) {
   text-align: center;
  }
`;

const LandingList = styled.ul`
  font-family: 'Lato Light', sans-serif;
  font-weight: 300;
  font-size: 22rem;
  color: #eee;
  padding-left: 10px;
  margin: 10px 0 10px 0;
  list-style-position: inside;
  position: relative;
  grid-column: 1;
  text-align: left;
  
  li {
    padding-bottom: 5px;
    opacity: 0;
  }
  
 @media screen and (max-width: 1440px) {
    font-size: 16rem;
  }

  @media screen and (max-width: 1220px) {
    font-size: 14rem;
  }
  
  @media screen and (max-width: 900px) {
      grid-column: 1 / 3;
  }
`;

const SpecButton = styled.a`
  position: relative;
  background-color: #363636;
  border: 1px solid #eeeeee;
  color: #eeeeee;
  cursor: pointer;
  padding: 5px;
  transition: background-color .6s ease;
  text-decoration: none;
  width: 200px;
  opacity: 0;
  margin-bottom: 25px;
  grid-column: 1;
  
  &:hover {
    background-color: #464646;
  }
  
  @media screen and (max-width: 900px) {
    justify-self: center;
    grid-column: 1 / 3;
  }
`;

const ButtonText = styled.span`
  font-family: 'Montserrat SemiBold', sans-serif;
  font-size: 16rem;
  line-height: 42px;
  margin-left: 25px;
`;

const RightButtonIcon = styled.img`
  width: 10px;
  transition: 0.4s ease-in-out;
  line-height: 42px;
  display: inline;
  vertical-align: -2px;
  margin-left: 25px;
  margin-right: 20px;

  ${SpecButton}:hover & {
    transform: rotate(90deg);
  }
  
  @media screen and (max-width: 720px) {
    transform: rotate(90deg);
  }
`;

const SpecSectionWrapper = styled.div`
  margin: auto;
  width: 90%;
  max-width: 900px;
`;

const SpecSectionHeader = styled.h1`
  font-family: 'Open Sans', sans-serif;
  font-size: 36rem;
  color: #363636;
  
  @media screen and (max-width: 900px) {
    font-size: 30rem;
  }
`;

const SpecSectionBody = styled.p`
  font-family: 'Lato Light', sans-serif;
  font-size: 20rem;
  color: #363636;
  
  @media screen and (max-width: 900px) {
    font-size: 16rem;
  }
`;

const GraphiteDiv = styled.div`
  pointer-events: none;
  width: 700px;
  grid-column: 2;
  grid-row: 1 / 7;
  justify-self: flex-end;
  position: relative;
  opacity: 0;

  @media screen and (max-width: 1440px) {
    width: 500px;
  }

  @media screen and (max-width: 1220px) {
    width: 400px;
  }
  
  @media screen and (max-width: 1020px) {
    width: 325px;
  }
  
  @media screen and (max-width: 900px) {
    width: 300px;
    grid-column: 1 / 3;
    grid-row: 3;
    justify-self: center;
  }
`;

const Section2 = styled.section`
  min-height: 100vh;
  width: 100%;
  background: #eeeeee;
  margin: 0;
  padding: 0;
  display: flex;
  overflow: hidden;
  position: relative;
`;

const TableStyle = styled.div`
  font-family: 'Lato', sans-serif;
  font-size: 16rem;
  text-align: center;
  margin: auto;
  width: 100%;
  padding-bottom: 20px;
  overflow: auto;

  table {
    border-spacing: 0;
    border: 2px solid #adadad;
    width: 100%;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 8rem;
      border-bottom: 1px solid #adadad;
      border-right: 1px solid #adadad;

      :last-child {
        border-right: 0;
      }
    }
  }
  
  td {
    background: #f3f3f3;
  }
`;

const scrollToRef = (ref) => window.scrollTo(0, ref.current.getBoundingClientRect().top);

const ElectrodePage = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const landingElements = [];

  useEffect(() => {
    dispatch({ type: "NAV_COLOR_CHANGE", color: "rgba(54, 54, 54, 0.8)" });
    new Vivus("graphite-div", {
      start: "autostart",
      duration: 75,
      file: GraphiteElectrode,
    }, null);

    let landingTween = new TimelineLite();
    landingTween.to(landingElements, 1, {
        delay: 0.1,
        autoAlpha: 1,
        overwrite: false,
      },
    );

  }, []);

  let specRef = useRef(null);

  const executeScroll = () => scrollToRef(specRef);

  return (
    <Layout>
      <SEO
        title="Electrodes"
        description="Trusted internationally across North America, Ceramark
        Technology Inc. supplies graphite and carbon electrodes for electric
        arc furnaces, ladle furnaces and foundries."
      />
      <Section1>
        <HeroWrapper>
          <HeroHeader ref={li => landingElements[0] = li}>
            Electrodes
          </HeroHeader>
          <HeroSubheader ref={li => landingElements[1] = li}>
            We supply graphite and carbon electrodes for electric arc furnaces,
            ladle furnaces and foundries.
          </HeroSubheader>
          <HeroBody ref={li => landingElements[2] = li}>
            Trusted across North America, our electrodes have performed
            reliably under high temperature with excellent
            conductivity, low oxidation, and high resistance to thermal shock.
          </HeroBody>
          <HeroBody ref={li => landingElements[3] = li}>
            We work upstream with our suppliers to ensure the quality of raw
            material they use.
            We then rigorously examine the machining both with our suppliers
            and with independent third parties to minimize chance of error.
          </HeroBody>
          <LandingList>
            <li ref={li => landingElements[4] = li}>
              Diameters range from 8" to 24" (177mm to 612mm)
            </li>
            <li ref={li => landingElements[5] = li}>
              Lengths range from 72" to 110" (1800mm to 2700mm)
            </li>
            <li ref={li => landingElements[6] = li}>
              Full range of grades for electrodes: HD, HP, FG, SHP, and UHP
            </li>
            <li ref={li => landingElements[7] = li}>
              All pins are UHP quality
            </li>
            <li ref={li => landingElements[8] = li}>
              Our sockets and pins align with NEMA standards
            </li>
          </LandingList>
          <SpecButton onClick={executeScroll} ref={li => landingElements[9] = li}>
            <ButtonText>
              Specifications
            </ButtonText>
            <RightButtonIcon src={RightChevron} alt="chevron"/>
          </SpecButton>
          <GraphiteDiv id="graphite-div" ref={li => landingElements[10] = li}/>
        </HeroWrapper>
      </Section1>
      <Section2>
        <SpecSectionWrapper>
          <SpecSectionHeader>
            Specifications
          </SpecSectionHeader>
          <SpecSectionBody>
            The size chart below shows our most commonly used sizes.<br/>
            Please note that we accomodate custom sizing that do not fall into
            sizes below.
          </SpecSectionBody>
          <TableStyle>
            <table ref={specRef}>
              <thead>
              <tr>
                <th colSpan="4">
                  Nominal Electrode Size
                </th>
                <th colSpan="2">
                  Tolerance
                </th>
                <th colSpan="2">
                  Standard Weight
                </th>
              </tr>
              <tr>
                <th>
                  Diameter (in)
                </th>
                <th>
                  Length (in)
                </th>
                <th>
                  Diameter (mm)
                </th>
                <th>
                  Length (mm)
                </th>
                <th>
                  Diameter ± (mm)
                </th>
                <th>
                  Length ± (mm)
                </th>
                <th>
                  lb
                </th>
                <th>
                  kg
                </th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>14</td>
                <td>72</td>
                <td>350</td>
                <td>1,800</td>
                <td>0.5</td>
                <td>50</td>
                <td>690</td>
                <td>310</td>
              </tr>
              <tr>
                <td>14</td>
                <td>84</td>
                <td>350</td>
                <td>2,100</td>
                <td>0.5</td>
                <td>50</td>
                <td>805</td>
                <td>365</td>
              </tr>
              <tr>
                <td>16</td>
                <td>72</td>
                <td>400</td>
                <td>1,800</td>
                <td>1</td>
                <td>50</td>
                <td>900</td>
                <td>410</td>
              </tr>
              <tr>
                <td>16</td>
                <td>84</td>
                <td>400</td>
                <td>2,100</td>
                <td>1</td>
                <td>50</td>
                <td>1010</td>
                <td>460</td>
              </tr>
              <tr>
                <td>18</td>
                <td>84</td>
                <td>450</td>
                <td>2,100</td>
                <td>1</td>
                <td>50</td>
                <td>1360</td>
                <td>620</td>
              </tr>
              <tr>
                <td>18</td>
                <td>96</td>
                <td>450</td>
                <td>2,400</td>
                <td>1</td>
                <td>100</td>
                <td>1550</td>
                <td>700</td>
              </tr>
              <tr>
                <td>20</td>
                <td>84</td>
                <td>500</td>
                <td>2,100</td>
                <td>1</td>
                <td>50</td>
                <td>1600</td>
                <td>730</td>
              </tr>
              <tr>
                <td>20</td>
                <td>96</td>
                <td>500</td>
                <td>2,400</td>
                <td>1</td>
                <td>100</td>
                <td>1920</td>
                <td>870</td>
              </tr>
              <tr>
                <td>22</td>
                <td>96</td>
                <td>550</td>
                <td>2,400</td>
                <td>1</td>
                <td>100</td>
                <td>2310</td>
                <td>1050</td>
              </tr>
              <tr>
                <td>22</td>
                <td>110</td>
                <td>550</td>
                <td>2,700</td>
                <td>1</td>
                <td>100</td>
                <td>2610</td>
                <td>1180</td>
              </tr>
              <tr>
                <td>24</td>
                <td>96</td>
                <td>600</td>
                <td>2,400</td>
                <td>1</td>
                <td>100</td>
                <td>2750</td>
                <td>1250</td>
              </tr>
              <tr>
                <td>24</td>
                <td>110</td>
                <td>600</td>
                <td>2,700</td>
                <td>1</td>
                <td>100</td>
                <td>3100</td>
                <td>1410</td>
              </tr>
              </tbody>
            </table>
          </TableStyle>
        </SpecSectionWrapper>
      </Section2>
    </Layout>
  );
};

export default ElectrodePage;
